import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { ProductionStepModel } from '@/models/productionStepModel';
import { ImageStoreConfiguration } from '@/config';
import { ClientModel } from '@/models/clientModel';

@Component
export default class ProductDetails extends Vue {

    @Prop()
    private product!: OrderLineDisplayModel;

    @Prop()
    private steps!: ProductionStepModel[];

    @Prop()
    private client!: ClientModel;

    @Prop()
    private windowHeight!: number;

    @Prop()
    private scrollPosition!: number;

    private brandLogoBaseUrl: string | undefined = ImageStoreConfiguration.baseUrl;

    private industryStd: string | undefined = process.env.VUE_APP_IndustryStd;

    private waterPercentage: number = 0;

    private waterDashArray: string = "";

    private energyPercentage: number = 0;

    private energyDashArray: string = "";

    private landPercentage: number = 0;

    private landDashArray: string = "";

    private savingBathtubs: string = "";

    private savingKM: string = "";

    private savingLandInM2: string = "";

    private productPictureAvailable: boolean = false;

    // private scrollPosition: number = 0;

    private hoverIndex: number = -1;

    private detailsContent: string = "";

    private savingOneXaxis: number = 0;

    private filledXaxis: number = 0;

    private savingTwoXaxis: number = 0;

    private kmXaxis: number = 0;

    private landXaxis: number = 78;

    private blockClass: boolean = false;

    private maxlength: number = 8;

    private showReadMore: boolean = false;

    private showReadLess: boolean = false;

    private async mounted(): Promise<void> {

        if (this.product.product.productPictureUrls.length > 0) {
            this.productPictureAvailable = true;
        } else {
            this.productPictureAvailable = false;
        }

        if (this.product.footprint.length > 0) {
            const bathtubs = Number(this.product.footprint[0]['savingBathtubs']);
            this.savingBathtubs = bathtubs.toFixed();
            if(this.savingBathtubs.length==1) {
                this.savingOneXaxis = 38;
                this.filledXaxis = 62;
            } else if(this.savingBathtubs.length==2) {
                this.savingOneXaxis = 38;
                this.filledXaxis = 65;
            } else if(this.savingBathtubs.length==3) {
                this.savingOneXaxis = 38;
                this.filledXaxis = 67;
            } else {
                this.savingOneXaxis = 38;
                this.filledXaxis = 67;
            }

            const km = Number(this.product.footprint[0]['savingKM']);
            this.savingKM = km.toFixed();
            const landInM2 = Number(this.product.footprint[0]['savingLandInM2']);
            this.savingLandInM2 = landInM2.toFixed();
            if(this.savingLandInM2.length<3) {
                this.landXaxis = 74;
            }

            const progressBar = document.getElementById('circular-progress') as HTMLElement;
            const progressBarCar = document.getElementById('circular-progress-car') as HTMLElement;
            const progressBarLand = document.getElementById('circular-progress-land') as HTMLElement;

            //For water circle
            let progressBarValue = 0;
            let parseString = Number(this.product.footprint[0]['savingWaterPerLitre']);
            let progressbarEndValue = Number(parseString.toFixed());
            this.waterPercentage = progressbarEndValue;
            this.waterDashArray = `${progressbarEndValue * 2.5}, 250`;

            //For energy circle
            let progressBarCarValue = 0;
            let parseStringCar = Number(this.product.footprint[0]['savingCO2PerGram']);
            let progressbarCarEndValue = Number(parseStringCar.toFixed());
            this.energyPercentage = progressbarCarEndValue;
            this.energyDashArray = `${progressbarCarEndValue * 2.5}, 250`;

            //For land circle
            let progressBarLandValue = 0;
            let parseStringLand = Number(this.product.footprint[0]['savingLandUse']);
            let progressbarLandEndValue = Number(parseStringLand.toFixed());
            this.landPercentage = progressbarLandEndValue;
            this.landDashArray = `${progressbarLandEndValue * 2.5}, 250`;
        } else {
            // this.showFootprint = false;
        }
    }

    private created(): void {
        let element = document.getElementById('product-details-desc') as HTMLElement;
        this.detailsContent = this.product.product.compositionMainFabric + " " + this.product.product.compositionSecondFabric;
        if(screen.width<536){
            this.maxlength = 3;
        }
        if (this.detailsContent.split(' ').length > this.maxlength) {
            this.detailsContent = this.detailsContent.split(' ').slice(0, this.maxlength).join(' ') + " ... ";
            this.showReadMore = true;
            this.showReadLess = false;
        } else {
            this.blockClass = true;
        }
    }

    private readMore(): void {
        this.detailsContent = this.product.product.compositionMainFabric + " " + this.product.product.compositionSecondFabric + " ";
        this.showReadMore = false;
        this.showReadLess = true;
    }

    private readLess(): void {
        this.detailsContent = this.detailsContent.split(' ').slice(0, this.maxlength).join(' ') + " ... ";
        this.showReadMore = true;
        this.showReadLess = false;
    }

    private get productPictureSrc(): boolean {
        if (!this.product.product || this.product.product.productPictureUrls === undefined || this.product.product.productPictureUrls == null) {
            return false;
        }
        return true;
    }

    private get numberOfStepsText(): number {
        let stepCnt = 0;
        this.steps.forEach(step => {
            if (step.showDetails) {
                stepCnt++;
            }
        });
        return stepCnt;
    }

    private get isSmall(): boolean {
        if (this.scrollPosition <= 10) {
            this.hoverIndex = -1;
        }

        return this.scrollPosition > 10;
    }

    private get brandLogoSrc(): string {
        const brand = this.client.topBrands.filter(t => t.brandName === this.product.product.brandName)[0];
        if (brand == null || brand.brandLogoUrl == null) {
            return "";
        }

        return `${this.brandLogoBaseUrl}${brand.brandLogoUrl}`;
    }
}
